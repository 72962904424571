html,
body {
	height: 100%;
}

.fixTableHead {
	border-collapse: inherit;
	border-spacing: 0;
}

.fixTableHead thead {
	position: sticky;
	top: 0;
}

.fixTableHead tfoot {
	position: sticky;
	bottom: 0;
}

.fixTableHead thead th:first-child,
.fixTableHead thead td:first-child,
.fixTableHead tfoot th:first-child,
.fixTableHead tfoot td:first-child {
	border-width: 1px;
}

.fixTableHead thead th,
.fixTableHead thead td,
.fixTableHead tfoot th,
.fixTableHead tfoot td {
	border-width: 1px 1px 1px 0;
}

.fixTableHead tbody th:first-child,
.fixTableHead tbody td:first-child {
	border-width: 0 1px 1px 1px;
}

.fixTableHead tbody th,
.fixTableHead tbody td {
	border-width: 0 1px 1px 0;
}

.fixTableHead tbody tr:last-child th,
.fixTableHead tbody tr:last-child td {
	border-width: 0 1px 0 0;
}

table.table.table-sm td{
	padding: 0;
}

table.table.table-sm td>div{
	padding: .25rem .25rem;
	border: 1px solid rgba(0,0,0,0);
}

table.table.table-sm td:focus{
	outline: none;
}

table.table.table-sm td:focus>div{
	border: 1px solid #000;
}



.btn:focus {
	border-color: var(--bs-btn-active-border-color);
}
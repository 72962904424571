.menu-btn {
    width: 100%;
    height: 64px;
    text-align: left;
    border: 4px solid rgba(0, 0, 0, 0);
}

.menu-btn .kbs {
    font-size: 14px;
}

.menu-btn i {
    padding: 16px 0;
}

button.menu-btn:focus-visible {
    border: 4px solid #fff;
    box-shadow: 0 0 16px 0 #000;
}

.menu-btn-plus {
    height: 64px;
    width: 64px;
    border: 4px solid rgba(0, 0, 0, 0);
}

button.menu-btn-plus:focus-visible {
    border: 4px solid #fff;
    box-shadow: 0 0 16px 0 #000;
}

.input-container {
  width: 100%;
}

.downshift-container {
  position: relative;
  flex-grow: 1;
}

.input {
  text-overflow: ellipsis;
  padding-right: 5em !important;
}

div[class="input-group"]>.downshift-container:not(:first-child)>.input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

div[class="input-group"]>.downshift-container:not(:last-child)>.input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-controls {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: inline-flex;
  justify-content: flex-end;
}

.input-controls button {
  display: flex;
  border: none;
  background: none;
  padding: 0 0.75em;
}

.divider-container {
  display: flex;
  align-items: center;
}

.divider-container .divider {
  height: 80%;
  border-right: 1px solid #ced4da;
}

.options-list {
  padding: 0;
  top: 100%;
  position: absolute;
  background-color: white;
  width: 100%;
  overflow: auto;
  transition: opacity 0.1s ease;
  border-radius: 0.28571429rem;
  box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
  border-color: #96c8da;
  border-width: 1px;
  border-style: solid;
  z-index: 1;
}

.max-height-200 {
  max-height: 200px;
}

.option {
  padding: 0.375rem 0.75rem;
}

.option.is-active {
  background-color: rgb(178, 212, 255);
}

.option.is-selected {
  background-color: rgb(38, 132, 255);
}

.option.is-virtualized {
  white-space: nowrap;
}

.bold-option-text {
  font-weight: bold;
}

.loading-dots-container {
  width: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-dots {
  width: 2em;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.loading-dot {
  animation: dot-keyframes 1s infinite ease-in-out;
  background-color: #000;
  border-radius: 2px;
  display: inline-block;
  height: 0.25em;
  width: 0.25em;
  opacity: 0;
}

.loading-dot:nth-child(2) {
  animation-delay: 0.167s;
}

.loading-dot:nth-child(3) {
  animation-delay: 0.333s;
}

@keyframes dot-keyframes {
  0% {
    opacity: 0;
    transform: scale(1, 1);
  }

  33% {
    opacity: 0.2;
    transform: scale(1.2, 1.2);
  }

  66% {
    opacity: 0.1;
    transform: scale(1.2, 1.2);
  }

  100% {
    opacity: 0;
    transform: scale(1, 1);
  }
}